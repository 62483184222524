import React from 'react'

import Input from '@superlogica/input'
import { useField } from 'formik'
import PropTypes from 'prop-types'

const Field = ({ label, ...props }) => {
  const [field, meta] = useField(props)

  return <Input label={label} {...field} {...props} {...meta} />
}

Field.propTypes = {
  label: PropTypes.string.isRequired
}

export default Field
