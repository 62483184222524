import { between } from 'polished'
import styled from 'styled-components'

import media from '../../helpers/media'

export const HeadingWrapper = styled.header`
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
  display: flex;
  flex-direction: column;
  text-align: ${(props) => props.align};
  max-width: 60%;

  ${(props) =>
    props.align !== 'left' &&
    ` margin-left: auto;
      margin-right: auto;
    `}

  ${media.lessThan('md')`
    margin-bottom: 35px;
    max-width: 80%;
  `}

  ${media.lessThan('sm')`
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  `}
`

export const HeadingDescription = styled.p`
  color: ${({ theme }) => theme.color.gray700};
  font-size: ${between('16px', '20px', '320px', '1920px')};
  margin-top: 12px;
  margin-bottom: 0;
`
