import { between } from 'polished'
import styled from 'styled-components'

import media from '../../helpers/media'

export const TitleLabel = styled.span`
  display: block;
  color: ${(props) => props.colorLabel};
  text-transform: uppercase;
  font-size: ${between('14px', '16px', '320px', '1920px')};
  font-weight: 700;
`

export const TitleContent = styled.span`
  color: ${(props) => props.color};
  font-family: var(--integralcf);
  font-weight: 600;

  span {
    color: ${({ theme }) => theme.color.red950};
  }
`

export const TitleTag = styled.h2`
  text-align: ${(props) => props.align};

  ${(props) => {
    const { appearance } = props

    if (appearance === 'small') {
      return `
        ${TitleContent} {
          font-size: ${between('20px', '24px', '320px', '1920px')};
        }
      `
    }

    if (appearance === 'medium') {
      return `
        ${TitleContent} {
          font-size: ${between('24px', '36px', '320px', '1920px')};
        }
      `
    }

    if (appearance === 'bigger') {
      return `
        ${TitleContent} {
          font-size: ${between('24px', '48px', '320px', '1920px')};
        }
      `
    }
  }}

  ${media.lessThan('sm')`
    text-align: center;
  `}
`
