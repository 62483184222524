import React, { useState, useRef } from 'react'

import PropTypes from 'prop-types'

import ChevronLeft from '../../../static/images/icons/chevron-left.svg'
import ChevronRight from '../../../static/images/icons/chevron-right.svg'
import * as S from './styled'

const Carousel = ({ children, slickSettings, total, appearance, withNav }) => {
  const [index, setIndex] = useState(1)

  let slider = useRef(null)

  const handleNext = () => {
    slider.slickNext()
  }

  const handlePrevious = () => {
    slider.slickPrev()
  }

  const afterChangeHandler = (currentSlide) => {
    setIndex(currentSlide + 1)
  }

  return (
    <>
      <S.CarouselWrapper>
        <S.CarouselSlider
          ref={(c) => (slider = c)}
          afterChange={afterChangeHandler}
          appearance={appearance}
          {...slickSettings}
        >
          {children}
        </S.CarouselSlider>

        {withNav && total > 1 && (
          <S.CarouselNav appearance={appearance}>
            {appearance === 'gallery' && (
              <S.CarouselSlideIndex>
                <span>0{index}</span> - 0{total}
              </S.CarouselSlideIndex>
            )}
            <S.CarouselButtonGroup>
              <S.CarouselPrev onClick={() => handlePrevious()}>
                <ChevronLeft />
              </S.CarouselPrev>
              <S.CarouselNext onClick={() => handleNext()}>
                <ChevronRight />
              </S.CarouselNext>
            </S.CarouselButtonGroup>
          </S.CarouselNav>
        )}
      </S.CarouselWrapper>
    </>
  )
}

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  total: PropTypes.number,
  slickSettings: PropTypes.object.isRequired,
  appearance: PropTypes.string,
  withNav: PropTypes.bool
}

export default Carousel
