import React from 'react'

import PropTypes from 'prop-types'

import Tile from '../Tile'
import * as S from './styled'

const TileGroup = ({ className, appearance, tiles, padding, ...props }) => (
  <S.TileGroupWrapper
    appearance={appearance}
    className={className}
    padding={padding}
    {...props}
  >
    {tiles.map((tile, key) => {
      return (
        <Tile
          key={key}
          label={tile.label}
          value={tile.value}
          appearance={appearance}
        />
      )
    })}
  </S.TileGroupWrapper>
)

TileGroup.defaultProps = {
  appearance: 'dark'
}

TileGroup.propTypes = {
  className: PropTypes.string,
  appearance: PropTypes.string,
  tiles: PropTypes.array.isRequired,
  padding: PropTypes.bool
}

export default TileGroup
