export default [
  {
    id: 1,
    title: '<span>Conteúdo</span> <span>relevante</span>',
    action: {
      to: 'https://blog.superlogica.com/xperience/guia-palestras-superlogica',
      label: 'Acessar guia completo de palestras'
    },
    body:
      'Curado com cuidado, todas as palestras são dadas por líderes de mercados com cases reais.',
    gallery: [
      {
        path: './images/gallery/speakers/8.png'
      },
      {
        path: './images/gallery/speakers/9.png'
      },
      {
        path: './images/gallery/speakers/10.png'
      },
      {
        path: './images/gallery/speakers/11.png'
      },
      {
        path: './images/gallery/speakers/12.png'
      },
      {
        path: './images/gallery/speakers/13.png'
      },
      {
        path: './images/gallery/speakers/14.png'
      }
    ],
    align: 'right'
  },
  {
    id: 2,
    title: '<span>Compartilhe com</span> <span>sua equipe</span>',
    action: {
      to: 'https://blog.superlogica.com/xperience/guia-palestras-superlogica',
      label: 'Acessar guia completo de palestras'
    },
    body:
      'O evento sempre trouxe conteúdo relevante para todos os departamentos da empresa: sucesso do cliente, inovação, financeiro, marketing, vendas, produto além de cases reais!',
    gallery: [
      {
        label: 'Superlógica Xperience 2019',
        path: './images/gallery/speakers/2.png'
      },
      {
        label: 'Superlógica Xperience 2019',
        path: './images/gallery/speakers/3.png'
      },
      {
        label: 'Superlógica Xperience 2019',
        path: './images/gallery/speakers/4.png'
      },
      {
        label: 'Superlógica Xperience 2019',
        path: './images/gallery/speakers/5.png'
      },
      {
        label: 'Superlógica Xperience 2019',
        path: './images/gallery/speakers/6.png'
      },
      {
        label: 'Superlógica Xperience 2019',
        path: './images/gallery/speakers/7.png'
      }
    ],
    align: 'left'
  }
]
