import React from 'react'

import PropTypes from 'prop-types'

import * as S from './styled'

const Testimonial = ({ testimony }) => (
  <S.TestimonialWrapper>
    <S.TestimonialImage image={testimony.image} />
    <S.TestimonialContent>
      <S.TestimonialText>{testimony.body}</S.TestimonialText>
      <S.TestimonialName>{testimony.name}</S.TestimonialName>
      <S.TestimonialRole>{testimony.role}</S.TestimonialRole>
    </S.TestimonialContent>
  </S.TestimonialWrapper>
)

Testimonial.propTypes = {
  testimony: PropTypes.object
}

export default Testimonial
