import React from 'react'

import PropTypes from 'prop-types'

import * as S from './styled'

const Section = ({ children, ...props }) => (
  <S.SectionWrapper {...props}>
    <S.SectionContent>{children}</S.SectionContent>
  </S.SectionWrapper>
)

Section.propTypes = {
  children: PropTypes.node.isRequired
}

export default Section
