import React from 'react'

import PropTypes from 'prop-types'

import * as S from './styled'

const Title = ({
  level,
  text,
  label,
  before,
  color,
  colorLabel,
  align,
  appearance
}) => {
  const tag = level > 6 ? 'h6' : `h${level}`

  return (
    <S.TitleTag
      as={tag}
      align={align}
      level={level}
      appearance={appearance}
      before={before}
    >
      {label && <S.TitleLabel colorLabel={colorLabel}>{label}</S.TitleLabel>}
      <S.TitleContent
        color={color}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </S.TitleTag>
  )
}

Title.defaultProps = {
  level: 2,
  appearance: 'medium',
  align: 'center',
  color: '#2F3A45',
  before: '',
  colorLabel: '#2F3A45'
}

Title.propTypes = {
  level: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  text: PropTypes.string,
  label: PropTypes.string,
  before: PropTypes.string,
  align: PropTypes.oneOf(['left', 'right', 'center']),
  appearance: PropTypes.oneOf(['small', 'medium', 'bigger']),
  color: PropTypes.string,
  colorLabel: PropTypes.string
}

export default Title
