import { between, rem } from 'polished'
import styled from 'styled-components'

import media from '../../helpers/media'
import Field from '../Field'

export const NewsletterWrapper = styled.div`
  position: relative;
  z-index: 4;
  padding-bottom: 60px;

  ${media.lessThan('sm')`
    padding-bottom: 30px;
  `}
`

export const NewsletterContent = styled.div`
  background-color: ${({ theme }) => theme.color.wine950};
  border-radius: 4px;
  padding: 40px;
  position: relative;
  box-shadow: 0px 3px 60px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${media.lessThan('md')`
    flex-direction: column;
    height: auto;
    border-left: none;
    padding: 32px;
  `}
`

export const NewsletterTitle = styled.h2`
  color: ${({ theme }) => theme.color.white};
  font-size: ${between('14px', '18px', '320px', '1920px')};
  width: 45%;
  line-height: ${rem(25)};
  font-family: var(--integralcf);
  font-weight: 600;

  span {
    color: ${({ theme }) => theme.color.red950};
  }

  ${media.lessThan('md')`
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
  `}
`
export const NewsletterForm = styled.form`
  width: 55%;
  display: flex;
  justify-content: flex-end;
  margin-left: 32px;

  > div {
    width: 100%;
  }

  button {
    margin-left: 16px;
    width: 160px;
  }

  ${media.lessThan('md')`
    width: 80%;
    margin-left: 0;
  `}

  ${media.lessThan('sm')`
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > div {
      width: 100%;
    }

    button {
      margin-top: 16px;
      width: 100%;
      margin-left: 0;
    }
  `}
`

export const NewsletterField = styled(Field)`
  ${media.lessThan('sm')`
    margin-bottom: 8px;
  `}
`

export const NewsletterDetail = styled.p`
  color: ${({ theme }) => theme.color.gray700};
  font-size: 12px;
  line-height: 1.4;
  padding: 20px 40px;

  a {
    font-weight: 700;
    color: ${({ theme }) => theme.color.gray700};
    text-decoration: underline;
  }

  ${media.lessThan('md')`
    padding: 20px 0;
    text-align: justify;
  `}
`
