import React from 'react'

import PropTypes from 'prop-types'

import Carousel from '../Carousel'
import * as S from './styled'

const slickSettings = {
  infinite: true,
  dots: false,
  arrows: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  fade: true,
  autoplay: true,
  autoplaySpeed: 2000,
  pauseOnHover: false
}

const Feature = ({ feature }) => (
  <S.FeatureWrapper align={feature.align}>
    <S.FeatureContent align={feature.align}>
      <S.FeatureContentInner>
        <S.FeatureTitle dangerouslySetInnerHTML={{ __html: feature.title }} />
        <S.FeatureDescription>{feature.body}</S.FeatureDescription>
        {feature.action && (
          <S.FeatureLink
            as="a"
            color="white"
            target="_blank"
            href={feature.action.to}
            title={feature.action.label}
          >
            {feature.action.label}
          </S.FeatureLink>
        )}
      </S.FeatureContentInner>
    </S.FeatureContent>
    <S.FeatureGallery align={feature.align}>
      <Carousel
        slickSettings={slickSettings}
        total={feature.gallery.length}
        appearance="gallery"
        withNav
      >
        {feature.gallery.map((img, key) => (
          <S.FeatureGalleryImage
            key={key}
            image={img.path}
            align={feature.align}
          >
            {img.label && (
              <S.FeatureGalleryLabel align={feature.align}>
                <span>{img.label}</span>
              </S.FeatureGalleryLabel>
            )}
          </S.FeatureGalleryImage>
        ))}
      </Carousel>
    </S.FeatureGallery>
  </S.FeatureWrapper>
)

Feature.propTypes = {
  feature: PropTypes.object
}

export default Feature
