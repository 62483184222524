import React from 'react'

import PropTypes from 'prop-types'

import * as S from './styled'

const Tile = ({ appearance, value, label }) => (
  <S.TileWrapper appearance={appearance}>
    <S.TileValue>{value}</S.TileValue>
    <S.TileLabel>{label}</S.TileLabel>
  </S.TileWrapper>
)

Tile.propTypes = {
  appearance: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string
}

export default Tile
