import React from 'react'
import { Container, Row, Col } from 'react-awesome-styled-grid'

import Carousel from '../components/Carousel'
import Feature from '../components/Feature'
import Heading from '../components/Heading'
import Hero from '../components/Hero'
import { XperienceText } from '../components/Isometric'
import Layout from '../components/Layout'
import Newsletter from '../components/Newsletter'
import Section from '../components/Section'
import SEO from '../components/Seo'
import Testimonial from '../components/Testimonial'
import TileGroup from '../components/TileGroup'
import Title from '../components/Title'
import { features, testimonials } from '../data'
import tiles from '../data/tiles'
import colors from '../style/tokens/colors'

const slickTestimonialsSettings = {
  infinite: true,
  speed: 400,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  arrows: false,
  fade: true,
  autoplay: true,
  autoplaySpeed: 3000,
  pauseOnHover: false,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        dots: true
      }
    }
  ]
}

const IndexPage = () => (
  <Layout>
    <SEO title="Evento sobre gestão, tecnologia e inovação" />

    <Hero id="intro" />

    <Section
      id="informativo"
      bgColor="#ffffff"
      className="speakers"
      paddingTop={1}
    >
      <Container>
        <Heading>
          <Title text="Superlógica Xperience" appearance="medium" />
        </Heading>
        <Row justify="center">
          <Col md={10} lg={8} style={{ fontSize: 18 }}>
            <p style={{ marginBottom: 12 }}>
              Dada a incerteza do cenário pandêmico, prezando pela segurança de
              todos os colaboradores e participantes do evento, optamos por não
              realizar o Xperience este ano.
            </p>
            <p style={{ marginBottom: 12 }}>
              Se você já comprou um ingresso e gostaria de receber o reembolso,
              a restituição dos valores de ingressos será feita pela Sympla,
              através do email{' '}
              <a
                href="mailto:participante@superlogica.com"
                title="participante@superlogica.com"
                target="_blank"
                className="link"
                rel="nofollow noopener noreferrer"
              >
                participante@superlogica.com
              </a>
              .
            </p>
            <p style={{ marginBottom: 12 }}>
              Caso deseje manter o ingresso para a próxima edição, é só manter
              como está. E para demais dúvidas, envie um e-mail{' '}
              <a
                href="mailto:eventops@superlogica.com"
                title="eventops@superlogica.com"
                target="_blank"
                className="link"
                rel="nofollow noopener noreferrer"
              >
                eventops@superlogica.com
              </a>
              .
            </p>
            <p>
              Para receber novidades sobre o próximo evento, deixe seus dados no
              campo abaixo. Prometemos que só enviaremos conteúdo sobre o
              Xperience, ok?
            </p>
          </Col>
        </Row>
      </Container>
    </Section>

    <Newsletter />

    <Section bgColor="#ffffff" paddingTop={1}>
      <Container>
        <Row justify="center">
          <Col md={10} lg={8} style={{ fontSize: 18 }}>
            <p style={{ marginBottom: 12 }}>
              Enquanto isso, você pode acompanhar o Superlógica Talks, o nosso
              podcast sobre empreendedorismo e tecnologia,{' '}
              <a
                href="https://www.youtube.com/playlist?list=PLoh4sxzyntdJFvBDs9427Fjy0iy_ODni1"
                title="clicando neste link"
                target="_blank"
                className="link"
                rel="nofollow noopener noreferrer"
              >
                clicando neste link
              </a>
              .
            </p>
            <p style={{ marginBottom: 12 }}>
              Ou então, participar do Superlógica Next 2022. Saiba mais{' '}
              <a
                href="https://next.superlogica.com/"
                target="_blank"
                className="link"
                rel="nofollow noopener noreferrer"
                title="clicando aqui"
              >
                clicando aqui.
              </a>
            </p>
            <p>
              <b>#JuntosSomosSuper</b>
            </p>
          </Col>
        </Row>
      </Container>
    </Section>

    <Section id="edicoes-anteriores" className="event" bgColor={colors.light}>
      <Container>
        <Heading>
          <Title
            text="Assista gratuitamente o <span>conteúdo</span> de três anos de evento"
            appearance="medium"
          />
        </Heading>

        <TileGroup tiles={tiles} fullWidth padding />

        <XperienceText />

        {features.map((feature, key) => (
          <Feature key={key} feature={feature} />
        ))}
      </Container>
    </Section>

    <Section id="depoimentos" paddingBottom={0}>
      <Container>
        <Heading>
          <Title
            colorLabel={colors.red950}
            align="center"
            text="Depoimentos"
            appearance="medium"
          />
        </Heading>

        <Row>
          <Col md={10} offset={{ md: 1 }}>
            <Carousel
              appearance="testimony"
              total={testimonials.length}
              slickSettings={slickTestimonialsSettings}
              withNav
            >
              {testimonials.map((testimony, key) => (
                <Testimonial key={key} testimony={testimony} withNav />
              ))}
            </Carousel>
          </Col>
        </Row>

        <XperienceText top={240} />
      </Container>
    </Section>
  </Layout>
)

export default IndexPage
