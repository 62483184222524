import { rem } from 'polished'
import styled from 'styled-components'

import media from '../../helpers/media'

export const TestimonialWrapper = styled.article`
  width: 100%;
  height: 300px;
  display: flex;

  ${media.lessThan('sm')`
    flex-direction: column;
    height: auto;
  `}
`

export const TestimonialImage = styled.div`
  background-image: url('${(props) => props.image}');
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 100%;

  ${media.lessThan('sm')`
    width: 100%;
    height: 300px;
    background-position: top center;
  `}
`

export const TestimonialContent = styled.div`
  background: #f2eded;
  padding: 60px;
  width: calc(100% - 300px);
  height: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${({ theme }) => theme.color.gray950};

  ${media.lessThan('sm')`
    width: 100%;
    min-height: 240px;
    padding: 32px;
  `}
`

export const TestimonialName = styled.h3`
  font-size: ${rem(16)};
  font-family: var(--integralcf);
  margin-bottom: 6px;
`

export const TestimonialRole = styled.div`
  text-transform: uppercase;
  opacity: 0.5;
  font-size: ${rem(14)};
  font-weight: 600;
`

export const TestimonialText = styled.p`
  color: ${({ theme }) => theme.color.gray700};
  font-size: ${rem(22)};
  margin-bottom: 16px;

  &:before,
  &:after {
    content: '“';
  }

  ${media.lessThan('sm')`
    font-size: ${rem(16)};
  `}
`
