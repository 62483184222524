import styled from 'styled-components'

import media from '../../helpers/media'

export const TileGroupWrapper = styled.div`
  padding: ${({ padding }) => (padding ? '40px 0' : '0')};
  display: flex;
  margin-bottom: ${({ theme }) => theme.sizes.size32}px;
  justify-content: ${({ fullWidth }) =>
    fullWidth ? 'space-between' : 'center'};

  > div {
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};

    &:not(:first-of-type) {
      margin-left: ${({ theme }) => theme.sizes.size16}px;
    }
    &:not(:last-of-type) {
      &:after {
        content: '';
        width: 1px;
        opacity: 0.5;
        height: ${({ theme }) => theme.sizes.size20}px;
        margin: auto;
        position: absolute;
        top: 0;
        right: -${({ theme }) => theme.sizes.size8}px;
        bottom: 0;

        ${({ theme, appearance }) => {
          if (appearance === 'dark') {
            return `
              background-color: ${theme.color.gray950};
            `
          }

          if (appearance === 'light') {
            return `
              background-color: ${theme.color.white};
            `
          }
        }}
      }
    }
  }

  ${media.lessThan('lg')`
    flex-wrap: wrap;
    justify-content: center;
    > div {
      width: 25%;
      flex-shrink: 0 !important;
      margin-left: 0 !important;
      margin-right: 0 !important;

      &:not(:last-of-type) {
        &:after {
          right: 0;
        }
      }

      &:nth-child(4n) {
        &:after {
          display: none;
        }
      }
    }
  `}

  ${media.lessThan('sm')`
    > div {
      width: 50%;
      margin-bottom: ${({ theme }) => theme.sizes.size32}px;

      &:nth-child(even) {
        &:after {
          display: none;
        }
      }
    }
  `}
`
