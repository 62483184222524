export default [
  {
    id: 1,
    name: 'Vitor Magnani',
    role: 'iFood',
    body:
      'O evento une diversos pontas da cadeia digital para promover a cultura de inovação.',
    image: './images/testimonial/vitor_magnani.png'
  },
  {
    id: 2,
    name: 'Renata Centurión',
    role: 'Winning by Design',
    body:
      'O país e o ecossistema melhoram com esse evento. O engajamento foi lá nas alturas!',
    image: './images/testimonial/renata_centurion.png'
  },
  {
    id: 3,
    name: 'Fabrício Bloisi',
    role: 'CEO Movile',
    body:
      'O sonho da Movile nasceu em campinas, em eventos 10x menores que esse. Por isso, estar aqui é importante.',
    image: './images/testimonial/fabricio_bloisi.png'
  },
  {
    id: 4,
    name: 'Sandra Miller',
    role: 'Chefe de Inovação Singularity U',
    body: 'Ao criar conexões com as pessoas daqui que a mágica acontece.',
    image: './images/testimonial/sandra_miller.png'
  },
  {
    id: 5,
    name: 'Lars Silberbauer',
    role: 'Ex-Lego e VP na Viacom MTV',
    body:
      'O evento presencial conecta pessoas de diferentes indústrias e isso é importantíssimo pois traz de volta o elemento humano nas organizações.',
    image: './images/testimonial/lars_silberbauer.png'
  }
]
