import React from 'react'
import { Container } from 'react-awesome-styled-grid'
import { toast } from 'react-toastify'

import { Button } from '@superlogica/button'
import { Formik } from 'formik'
import * as Yup from 'yup'

import hubspot from '../../helpers/hubspot'
import * as S from './styled'

const initialValues = {
  email: ''
}

const validationSchema = Yup.object({
  email: Yup.string()
    .email('E-mail inválido')
    .required('Campo obrigatório')
})

const Newsletter = () => {
  const onSubmit = async (
    values,
    { setSubmitting, setErrors, setStatus, resetForm }
  ) => {
    console.log(values)
    try {
      await hubspot({
        method: 'post',
        url: '001a325c-87fe-454a-bffb-43370b045461',
        data: {
          fields: [
            {
              name: 'email',
              value: values.email
            }
          ],
          context: {
            pageUri: window.location.href,
            pageName: 'Superlógica Xperience 2020'
          }
        }
      })

      resetForm(initialValues)
      setStatus({ success: true })

      toast.success('E-mail cadastrado com sucesso.', {
        hideProgressBar: true
      })
    } catch (error) {
      setStatus({ success: false })
      setSubmitting(false)
      setErrors({ submit: error.message })

      toast.error('Ops, ocorreu um erro ao cadastrar o seu e-mail.', {
        hideProgressBar: true,
        position: 'top-center'
      })
    }
  }

  return (
    <S.NewsletterWrapper id="receba-novidades">
      <Container>
        <S.NewsletterContent>
          <S.NewsletterTitle>
            Inscreva-se para receber descontos e novidades do Superlógica{' '}
            <span>Xperience</span>
          </S.NewsletterTitle>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(formik) => {
              return (
                <S.NewsletterForm onSubmit={formik.handleSubmit}>
                  <S.NewsletterField
                    bgColor="wine950"
                    borderColor="neutral"
                    textColor="white"
                    label="Seu e-mail"
                    name="email"
                    value={formik.values.email}
                    floatingMessage
                    type="email"
                    status={
                      formik.touched.email && formik.errors.email
                        ? 'error'
                        : null
                    }
                    message={
                      formik.touched.email && formik.errors.email
                        ? formik.errors.email
                        : null
                    }
                  />

                  <Button color="red950" type="submit">
                    Inscrever
                  </Button>
                </S.NewsletterForm>
              )
            }}
          </Formik>
        </S.NewsletterContent>
        <S.NewsletterDetail>
          Ao preencher nossos formulários, navegar nos nossos canais e solicitar
          a utilização dos nossos serviços, você concorda com a coleta e
          tratamento dos dados fornecidos para melhorar a sua experiência com a
          Superlógica. Em caso de dúvidas, acesse nossa{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            title="Política de Privacidade"
            href="https://superlogica.com/privacidade/?fbclid=IwAR107Ese22Hp0GAx_Um5KIhYrtvR-lDOuzE_12tE_xhhsNQ-abjMMAYnZiI&amp;_ga=2.242010434.1564986813.1579886668-1735264534.1576519513"
          >
            Política de Privacidade
          </a>
          .
        </S.NewsletterDetail>
      </Container>
    </S.NewsletterWrapper>
  )
}

export default Newsletter
