import React from 'react'

import PropTypes from 'prop-types'

import * as S from './styled'

const Heading = ({ align, children, body, marginBottom }) => (
  <S.HeadingWrapper align={align} marginBottom={marginBottom}>
    {children}
    <S.HeadingDescription>{body}</S.HeadingDescription>
  </S.HeadingWrapper>
)

Heading.defaultProps = {
  align: 'center',
  marginBottom: 40
}

Heading.propTypes = {
  align: PropTypes.string,
  children: PropTypes.node.isRequired,
  body: PropTypes.string,
  marginBottom: PropTypes.number
}

export default Heading
