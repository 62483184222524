import styled from 'styled-components'

import media from '../../helpers/media'

export const SectionWrapper = styled.section`
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;

  .link {
    font-weight: 700;
    color: #ff2d2d;

    &:hover {
      color: #D12525;
    }
  }

  ${(props) =>
    props.noPadding &&
    `
    padding: 0 !important;
  `}

  ${(props) =>
    props.paddingBottom &&
    `
      padding-bottom: ${props.paddingBottom}px;
    `}

  ${(props) =>
    props.paddingTop &&
    `
      padding-top: ${props.paddingTop}px;
    `}

  ${(props) =>
    props.paddingLeft &&
    `
      padding-left: ${props.paddingLeft}px;
    `}

  ${(props) =>
    props.paddingRight &&
    `
      padding-right: ${props.paddingRight}px;
    `}

  ${(props) =>
    props.fullHeight &&
    `
    height: fill-available;
    height: 100vh;
  `}

  ${(props) =>
    props.bgImage &&
    `
    background-image: url(${props.bgImage});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top right;
    margin-top: -100px;
  `}

  ${(props) =>
    props.bgColor &&
    `
    background-color: ${props.bgColor};
  `}

  ${(props) =>
    props.halfBg &&
    `
    margin-top: -100px;
    &:before {
      content: '';
      background-color: ${props.halfBg};
      width: 100%;
      height: 400px;
      position: absolute;
      top: -290px;
      left: 0;
    }
  `}

  ${(props) =>
    props.arrowBg &&
    `
    padding-top: 526px;
    padding-bottom: 100px;
    position: relative;
    background: url(./images/ticket/arrows.svg) ${props.arrowBg};
    background-repeat: no-repeat;
    background-position: left top;
    margin-top: -502px;
  `}

  ${media.lessThan('sm')`
    ${(props) =>
      props.bgImage &&
      `
        padding-top: 200px;
      `}
  `}

  &.speakers {
    ${media.lessThan('sm')`
      padding-top: 30px;
      padding-bottom: 40px;
    `}
  }

  &.event {
    ${media.lessThan('sm')`
      padding-bottom: 0;
    `}
  }
`

export const SectionContent = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
`
