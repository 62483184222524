import { ButtonOutline } from '@superlogica/button'
import { between, rem } from 'polished'
import styled from 'styled-components'

import media from '../../helpers/media'

export const FeatureContent = styled.div`
  padding: 80px 90px;
  width: 50%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.red950};

  ${media.lessThan('md')`
    width: 100%;
    padding: 80px 90px 120px;
  `}

  ${media.lessThan('sm')`
    padding: 60px 24px 120px;
  `}
`

export const FeatureWrapper = styled.article`
  width: 100%;
  display: flex;
  height: 580px;
  position: relative;
  flex-direction: ${({ align }) => (align === 'left' ? 'row-reverse' : 'row')};

  ${media.lessThan('md')`
    display: flex;
    flex-direction: column;
    height: auto;
  `}

  ${media.lessThan('sm')`
    margin-left: -24px;
    margin-right: -24px;
    width: 100vw;
  `}

  &:nth-child(odd) {
    ${FeatureContent} {
      background-image: url('./images/x-arrows.svg');
      background-position: center left;
      background-position-x: 200px;
      background-repeat: no-repeat;
    }
  }
`

export const FeatureContentInner = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
`

export const FeatureGallery = styled.div`
  width: 50%;
  height: 580px;

  ${media.lessThan('md')`
    width: 100%;
    height: auto;
  `}
`

export const FeatureGalleryImage = styled.div`
  background-image: url('${(props) => props.image}');
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: relative;
  display: block !important;

  ${media.lessThan('md')`
    width: 100%;
    height: 400px;
  `}
`

export const FeatureLink = styled(ButtonOutline)`
  margin-top: 24px;
`

export const FeatureGalleryLabel = styled.span`
  position: absolute;
  bottom: 40px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.white};
  font-weight: 600;
  font-size: ${rem(14)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 90px);
  z-index: 1;

  span {
    flex-shrink: 0;
  }

  &:after {
    content: '';
    width: 100%;
    height: 2px;
    background-color: ${({ theme }) => theme.color.white};
  }

  ${({ align }) => {
    if (align === 'left') {
      return `
        left: calc(100% + 58px);

        span {
          margin-left: 32px;
        }

        &:after {
          position: absolute;
          left: -100%;
        }
      `
    }

    if (align === 'right') {
      return `
        left: calc(-100% + 90px);

        span {
          margin-right: 32px;
        }
      `
    }
  }}

  ${media.lessThan('md')`
    top: -60px;
    bottom: auto;
    align-items: flex-start;

    &:after {
      height: 60px;
      width: 2px;
    }

    ${({ align }) => {
      if (align === 'left') {
        return `
          justify-content: flex-start;
          left: 90px;

          span {
            margin-left: 16px;
          }

          &:after {
            position: absolute;
            left: 0;
          }
        `
      }

      if (align === 'right') {
        return `
          justify-content: flex-end;
          span {
            margin-right: 16px;
          }
        `
      }
    }}
  `}

  ${media.lessThan('sm')`
    left: 24px;
  `}
`

export const FeatureTitle = styled.h3`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  font-size: ${between('18px', '25px', '320px', '1920px')};
  font-weight: 600;

  span {
    font-family: var(--integralcf);
    background: ${({ theme }) => theme.color.white};
    margin: 5px 0;
    padding: 0 10px 3px;
  }
`
export const FeatureDescription = styled.p`
  color: ${({ theme }) => theme.color.white};
  margin-top: 20px;
  line-height: ${rem(24)};
`
