import { between } from 'polished'
import styled from 'styled-components'

export const TileWrapper = styled.div`
  position: relative;
  padding: 0 ${({ theme }) => `${theme.sizes.size20}px`};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${({ theme, appearance }) => {
    if (appearance === 'dark') {
      return `
        color: ${theme.color.gray950};
      `
    }

    if (appearance === 'light') {
      return `
        color: ${theme.color.white};
      `
    }
  }}
`

export const TileValue = styled.span`
  font-size: ${between('24px', '32px', '320px', '1920px')};
  font-family: var(--integralcf);
  font-weight: 600;
`

export const TileLabel = styled.span`
  font-size: ${between('16px', '20px', '320px', '1920px')};
`
