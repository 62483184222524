import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import { rem, rgba } from 'polished'
import styled from 'styled-components'

import media from '../../helpers/media'

export const CarouselWrapper = styled.div`
  position: relative;
  height: 100%;
`

export const CarouselSlider = styled(Slider)`
  .slick-slide {
    position: relative;
    height: auto;

    > div {
      height: 100% !important;
    }
  }

  .slick-list {
    overflow: inherit;
    padding: 0 !important;
  }

  .slick-track {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;

    &:before,
    &:after {
      display: none;
    }
  }

  .slick-dots {
    display: flex !important;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: -32px;
    left: 0;
    right: 0;

    li {
      flex-shrink: 0;
      margin: 0 8px;

      button {
        background-color: ${({ theme }) => theme.color.gray300};
        border: none;
        width: 8px;
        height: 8px;
        display: block;
        border-radius: 100%;
        text-indent: -9999px;
        padding: 0;
      }

      &.slick-active {
        button {
          width: 12px;
          height: 12px;
          background-color: ${({ theme }) => theme.color.red950};
        }
      }
    }
  }

  ${({ appearance }) =>
    appearance === 'gallery' &&
    `
      &, .slick-list, .slick-track {
        height: 100%;
      }
    `}

  ${({ appearance }) =>
    appearance === 'carousel' &&
    `
      .slick-slide {
        &:not(:first-of-type) {
          margin-left: 16px;
        }

        &:not(:last-of-type) {
          margin-right: 16px;
        }
      }
    `}
`

export const CarouselButtonGroup = styled.div``

export const CarouselButton = styled.button`
  background: transparent;
  border: 0;
  box-shadow: none;
  outline: none;
  opacity: 0.4;
  color: ${({ theme }) => theme.color.gray950};
  transition: all ease 0.4s;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    opacity: 1;
  }
`

export const CarouselPrev = styled(CarouselButton)``

export const CarouselNext = styled(CarouselButton)`
  order: 1;
`

export const CarouselSlideIndex = styled.span`
  font-size: ${rem(26)};
  span {
    width: 32px;
    display: inline-block;
    color: ${({ theme }) => theme.color.red950};
  }
`

export const CarouselNav = styled.div`
  width: 100%;
  height: 100px;
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 0;
  left: 0;
  z-index: 3;

  ${({ appearance, theme }) =>
    appearance === 'gallery' &&
    `
      background-color: ${theme.color.white};
      border: 1px solid ${rgba('#E9EBEC', 0.4)};
      padding: 0 32px;

      ${CarouselButtonGroup} {
        display: flex;
        align-content: center;

        &:before {
          content: '';
          width: 1px;
          height: 10px;
          display: block;
          background-color: ${theme.color.gray200};
          margin: auto 16px;
          order: 1;
        }
      }
  `}

  ${({ appearance }) =>
    appearance === 'testimony' &&
    `
      height: 32px;
      top: 0;
      margin: auto;

      ${CarouselButtonGroup} {
        width: 100%;
      }

      ${CarouselPrev},
      ${CarouselNext} {
        margin: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 199;
      }

      ${CarouselPrev} {
        left: -40px;
      }

      ${CarouselNext} {
        right: -40px;
      }
  `}

  ${media.lessThan('sm')`
    ${({ appearance }) =>
      appearance === 'testimony' &&
      `
        display: none;
    `}

    ${({ appearance }) =>
      appearance === 'gallery' &&
      `
        position: relative;
    `}
  `}
`
