export default [
  {
    id: 1,
    label: 'anos de evento',
    value: '3'
  },
  {
    id: 2,
    label: 'horas de conteúdo',
    value: '60'
  },
  {
    id: 3,
    label: 'palestrantes',
    value: '+175'
  },
  {
    id: 4,
    label: 'palestras',
    value: '+90'
  }
]
